<template>
	<ion-page>
		<ion-header :translucent="false">
			<ion-toolbar v-if="!loading">
				<ion-buttons slot="start">
					<ion-back-button text="Zurück"></ion-back-button>
				</ion-buttons>
				<ion-title>WEIN & CO</ion-title>
			</ion-toolbar>
		</ion-header>

		<ion-content>
			<ion-header v-if="events && !loading" collapse="condense" class="ion-padding-top">
				<ion-toolbar>
					<ion-title size="large">WEIN & CO</ion-title>
				</ion-toolbar>
			</ion-header>
			<div v-if="loading" class="ion-text-center">
				<br /><br /><br />
				<ion-spinner></ion-spinner>
			</div>
			<div v-if="events && !loading">
				<template v-for="(event) in events" :key="event">
					<ion-img v-if="event.imageUrl" :src="event.imageUrl" :alt="event.title"></ion-img>
					<h3 v-else class="ion-padding-horizontal ion-padding-top" style="margin-bottom:0px;" size="large">{{ event.title }}</h3>

					<ion-list class="ion-margin-top">
						<ion-item v-if="event.numberOfBooths > 0" detail="true" @click="goToEventBoothsPage(event)">
							<ion-label color="primary">
								Übersichtsplan
							</ion-label>
						</ion-item>
						<ion-item v-if="!event.searchOnServer" detail="true" @click="goToEventPage(event)">
							<ion-label color="primary">
								Alle Produzenten / Produkte
							</ion-label>
						</ion-item>
						<ion-item v-if="event.searchOnServer" detail="true" @click="goToEventSearchPage(event)">
							<ion-label color="primary">
								Produzenten / Produkte suchen
							</ion-label>
						</ion-item>
						<ion-item v-if="event.products.length > 0" detail="true" @click="openEmailModal(event)">
							<ion-label color="primary">
								Liste als E-Mail senden
							</ion-label>
						</ion-item>
						<ion-item v-if="event.products.length > 0" detail="true" @click="openShopUrl(event)">
							<ion-label color="primary">
								Jetzt meine Weine bestellen
							</ion-label>
						</ion-item>
					</ion-list>

					<ion-list v-if="event.products.length > 0">
						<template v-for="(product) in event.products" :key="product">
							<ion-item-sliding>
								<ion-item detail="true" @click="goToProductPage(product)">
									<ion-thumbnail slot="start">
										<img class="ion-padding-vertical-sm object-contain" :src="product.imageUrl" :alt="product.name" />
									</ion-thumbnail>
									<ion-label>
										<strong>{{ product.name }}</strong><br><span v-if="product.producerName">{{ product.producerName }}<span v-if="product?.producerBoothInfo"> | {{ product?.producerBoothInfo }}</span></span><span v-if="!product?.inStock"> | <ion-text color="primary">derzeit nicht verfügbar</ion-text></span>
										<div v-if="product.ratings.length > 0">
											<star-rating v-if="product.ratings[0].rating" :read-only="true" :star-size="14" :show-rating="false" :rating="product.ratings[0].rating"></star-rating>
											<ion-text v-if="product.ratings[0].note" class="ion-margin-top" style="white-space:normal; color:#6b7280">{{ product.ratings[0].note }}</ion-text>
										</div>
									</ion-label>
								</ion-item>

								<ion-item-options side="end">
									<ion-item-option @click="deleteRating(product, event)" color="danger">
										<ion-icon slot="icon-only" :icon="trash"></ion-icon>
									</ion-item-option>
								</ion-item-options>
							</ion-item-sliding>
						</template>
					</ion-list>

					<div v-if="identityStore?.identity && event.products.length > 0" class="ion-text-center ion-padding-left ion-padding-top ion-padding-right">
						Tipp: Sie können Ihre persönliche Liste<br> jederzeit über <a target="_blank" :href="'https://mein.weinco.at/?i=' + identityStore.identity">diesen Link</a> öffnen.
					</div>

					<div v-if="event.description && event.showDescriptionOnRootPage" class="" style="margin-top:0px; margin-bottom:0px;">
						<div class="ion-padding-horizontal ion-padding-top" v-html="event.description"></div>
					</div>

					<div v-if="event.footerImageUrl && event.showFooterImageOnRootPage" style="margin-top:30px; margin-bottom:-30px;">
						<ion-img class="ion-margin-top" :src="event.footerImageUrl" :alt="event.name"></ion-img>
					</div>
					<br />
				</template>
			</div>
			<footer-content v-if="events && !loading"></footer-content>
		</ion-content>
	</ion-page>
</template>

<script>
import {
	IonText,
	IonContent,
	IonSpinner,
	IonHeader,
	IonPage,
	IonIcon,
	IonLabel,
	IonImg,
	IonThumbnail,
	IonItemSliding,
	IonItemOptions,
	IonItemOption,
	IonItem,
	IonList,
	IonTitle,
	IonToolbar,
	IonBackButton,
	IonButtons,
	modalController,
	toastController,
} from '@ionic/vue';
import { qrCode } from 'ionicons/icons';
import { useRouter } from 'vue-router';
import { onIonViewWillEnter } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useEventStore } from '../stores/EventStore';
import { useProductStore } from '../stores/ProductStore';
import { useIdentityStore } from '../stores/IdentityStore';
import EmailModal from '../components/EmailModal.vue';
import StarRating from 'vue-star-rating';
import FooterContent from '../components/FooterContent.vue';
import { trash } from 'ionicons/icons';

export default defineComponent({
	name: 'RootPage',
	components: {
		IonText,
		IonImg,
		IonList,
		IonThumbnail,
		IonItem,
		IonLabel,
		IonContent,
		IonHeader,
		IonPage,
		IonItemSliding,
		IonItemOption,
		IonItemOptions,
		IonBackButton,
		IonButtons,
		IonTitle,
		IonIcon,
		IonSpinner,
		IonToolbar,
		StarRating,
		FooterContent
	},
	setup() {
		const identityStore = useIdentityStore();
		const eventStore = useEventStore();
		const productStore = useProductStore();
		const events = ref(null);
		const loading = ref(false);
		const router = useRouter();

		onIonViewWillEnter(async () => {
			loading.value = true;
			await eventStore.getEvents(identityStore.identity);
			events.value = eventStore.events;
			loading.value = false;
		});

		const openEmailModal = async (email) => {
			const modal = await modalController.create({
				component: EmailModal,
				componentProps: {
					event: email.token,
				},
			});
			modal.present();
			await modal.onWillDismiss();
		};

		const openShopUrl = async (event) => {
			const productIds = event.products.map(product => product.shopId);
			const idsQueryParam = productIds.join(',');
			const baseUrl = 'https://www.weinco.at/warenkorb/addbulk/Produkt/';
			const url = `${baseUrl}${idsQueryParam}`;
			console.log(url);
			window.open(url, '_blank');
		};

		const goToEventPage = (event) => {
			router.push({
				name: 'Event',
				params: { id: event.token }
			})
		}

		const goToEventSearchPage = (event) => {
			router.push({
				name: 'EventSearch',
				params: { id: event.token }
			})
		}

		const goToEventBoothsPage = (event) => {
			router.push({
				name: 'EventBooths',
				params: { id: event.token }
			})
		}

		const goToProductPage = (product) => {
			router.push({
				name: 'Product',
				params: { id: product.token }
			})
		}

		const presentToast = async (message) => {
			const toast = await toastController.create({
				message: message,
				duration: 1500,
				position: 'bottom',
				color: 'light',
			});

			await toast.present();
		};

		const deleteRating = async (product, event) => {
			await productStore.deleteRating(product, identityStore.identity);
			const productIndex = event.products.findIndex((p) => p.token === product.token);

			if (productIndex !== -1) {
				event.products.splice(productIndex, 1);
			}
		};

		return {
			loading,
			trash,
			events,
			qrCode,
			openEmailModal,
			openShopUrl,
			presentToast,
			goToEventPage,
			goToEventSearchPage,
			goToEventBoothsPage,
			goToProductPage,
			deleteRating,
			identityStore
		};
	},
})
</script>


<style scoped>
ion-thumbnail img {
	object-fit: contain;
}
</style>
