<template>
	<ion-page>
		<ion-header :translucent="false">
			<ion-toolbar>
				<ion-buttons slot="start">
					<ion-back-button text="Zurück" :default-href="booth && booth.event !== undefined ? '/e/' + booth.event.token + '/booths': '/'">Zurück</ion-back-button>
				</ion-buttons>
				<ion-title @click="goToRootPage()">WEIN & CO</ion-title>
				<ion-buttons slot="end">
					<ion-button @click="goToRootPage()">
						Meine Weine
					</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>

		<ion-content>
			<div v-if="loading" class="ion-text-center">
				<br/><br/><br/>
				<ion-spinner></ion-spinner>
			</div>
			<div v-if="booth && !loading">
				<ion-img @click="goToEventBoothsPage(booth.event)" v-if="booth.imageUrl" :src="booth.imageUrl" :alt="booth.name"></ion-img>
				<ion-img @click="goToEventBoothsPage(booth.event)" v-if="!booth.imageUrl && booth.event.locationImageUrl" :src="booth.event.locationImageUrl" :alt="booth.event.title"></ion-img>
				<h3 class="ion-padding-horizontal" style="margin-bottom:0px;" size="large">{{ booth.name }}</h3>
				<div v-if="booth.description" class="ion-padding-horizontal ion-padding-top">{{ booth.description }}</div>
				<ion-list class="">
					<template v-for="(producer) in booth.producers" :key="producer">
						<ion-item detail="true" @click="goToProducerPage(producer)">
							<ion-label>
								{{ producer?.name }}
							</ion-label>
						</ion-item>
					</template>
				</ion-list>
				<div v-if="booth.event.footerImageUrl" style="margin-top:40px;margin-bottom:-30px;">
					<ion-img class="ion-margin-top" :src="booth.event.footerImageUrl" :alt="booth.name"></ion-img>
				</div>
			</div>
			<footer-content v-if="booth && !loading"></footer-content>
		</ion-content>
	</ion-page>
</template>

<script>
import { IonContent, IonHeader, IonSpinner, IonPage, IonTitle, IonToolbar, IonBackButton, IonList, IonItem, IonLabel } from '@ionic/vue';
import { defineComponent, onMounted, ref } from 'vue';
import { useBoothStore } from '../stores/BoothStore';
import { useRouter } from 'vue-router';
import FooterContent from '../components/FooterContent.vue';

export default defineComponent({
	name: 'BoothPage',
	props: ['id', 'boothId'],
	components: {
		IonContent,
		IonHeader,
		IonPage,
		IonTitle,
		IonBackButton,
		IonToolbar,
		IonList,
		IonItem,
		IonLabel,
		IonSpinner,
		FooterContent
	},
	setup(props) {
		const boothStore = useBoothStore();
		const loading = ref(false);
		const booth = ref(null);
		const router = useRouter();

		onMounted(async () => {
			loading.value = true;
			await boothStore.getBooth(props.id, props.boothId);
			booth.value = boothStore.booth;
			loading.value = false;
		});

		const goToRootPage = () => {
			router.push({
				name: 'Root',
				params: {},
			});
		};

		const goToEventBoothsPage = (event) => {
			router.push({
				name: 'EventBooths',
				params: { id: event.token }
			})
		};

		const goToProducerPage = (producer) => {
			router.push({
				name: 'Producer',
				params: { id: producer.token },
			});
		};

		return {
			loading,
			booth,
			goToRootPage,
			goToEventBoothsPage,
			goToProducerPage,
		};
	},
});
</script>

<style scoped>
ion-spinner {
	--color: #bc0936;
}
</style>
