<template>
	<ion-page>
		<ion-header :translucent="false">
			<ion-toolbar v-if="!loading">
				<ion-buttons slot="start">
					<ion-back-button text="Zurück" :default-href="'/'"></ion-back-button>
				</ion-buttons>
				<ion-title @click="goToRootPage()">WEIN & CO</ion-title>
			</ion-toolbar>
		</ion-header>

		<ion-content>
			<ion-header collapse="condense" class="ion-padding-top">
				<ion-toolbar>
					<ion-title size="large">WEIN & CO</ion-title>
				</ion-toolbar>
			</ion-header>
			<h3 class="ion-padding-horizontal ion-padding-top" style="margin-bottom:0px;" size="large">
				Impressum
			</h3>
			<p class="ion-margin-top ion-padding-horizontal">
				<strong>Medieninhaber & Herausgeber</strong><br>
				WEIN & CO Handelsgesellschaft m.b.H.<br>
				Hans Dujsik-Allee 7 / Top 25<br>
				A-2334 Vösendorf-Süd
				<br><br>
				Geschäftsführung: Hannes Scheufele, Alexander Borwitzky
				<br><br>
				Telefon: <a href="tel:004350706">+43 5 07 06</a><br>
				E-Mail: <a href="mailto:office@weinco.at">office@weinco.at</a>
			</p>

			<p class="ion-margin-top ion-padding-horizontal">
				<strong>Design, Konzeption & Programmierung</strong><br>
				Gramm & Bytes Medien GmbH<br>
				Wienerstraße 12/DG/3, 2120 Wolkersdorf<br>
				<a href="mailto:office@gramm-bytes.com">office@gramm-bytes.com</a><br>
				<a href="https://www.gramm-bytes.com" target="_blank">www.gramm-bytes.com</a>
			</p>

			<p class="ion-margin-top ion-padding-horizontal">
				<strong>Registereintrag</strong><br>
				Rechtsform: Gesellschaft mit beschränkter Haftung (GmbH)<br>
				Unternehmensgegenstand: Handel & Gastronomie<br>
				Firmenbuchgericht: Landesgericht Wr. Neustadt<br>
				Firmenbuchnummer: 108595z<br>
				DVR Nummer: 0370991<br>
				UID Nummer: ATU15004108
			</p>

			<p class="ion-margin-top ion-padding-horizontal">
				<strong>Eintragshinweis</strong><br>
				Behörde: Bezirkshauptmannschaft Mödling<br>
				Mitglied der Wirtschaftskammer Niederösterreich<br>
				Gewerbevorschrift: Gewerbeordnung unter <a target="_blank" href="www.ris2.bka.gv.at">www.ris2.bka.gv.at</a><br>
				Blattlinie: Das Webportal dient der Bewerbung und dem Absatz von uns vertriebener Waren und Dienstleistungen sowie der Darstellung des Unternehmens.<br>
			</p>

			<p class="ion-margin-top ion-padding-horizontal">
				<strong>Haftungshinweis</strong><br>
				Die Benutzung erfolgt auf eigene Gefahr. Wir weisen ausdrücklich darauf hin, dass wir für die Angebote Dritter im Internet (sogenannte "Deep Links") nicht verantwortlich sind. Soweit Links auf die Angebote Dritter verweisen, enthalten diese nach unserer Kenntnis keine strafbaren oder anderweitig verbotenen Inhalte. Für diese Inhalte wird keinerlei Haftung übernommen. Außerdem ist WEIN & CO für Inhalte, die durch einen Urhebervermerk oder entsprechendes Branding als fremde Inhalte gekennzeichnet sind, nicht verantwortlich und lehnt jegliche Haftung für diese Inhalte ab.
			</p>
			<footer-content></footer-content>
		</ion-content>
	</ion-page>
</template>

<script>
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonBackButton,
	IonButtons,
} from '@ionic/vue';
import { useRouter } from 'vue-router';
import { defineComponent } from 'vue';
import FooterContent from '../components/FooterContent.vue';

export default defineComponent({
	name: 'ImprintPage',
	components: {
		IonContent,
		IonHeader,
		IonPage,
		IonBackButton,
		IonButtons,
		IonTitle,
		IonToolbar,
		FooterContent
	},
	setup() {
		const router = useRouter();

		const goToRootPage = () => {
			router.push({
				name: 'Root',
				params: {},
			});
		};

		return {
			goToRootPage
		};
	},
})
</script>
