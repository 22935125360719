import { defineStore } from 'pinia';
import config from '../../config';

export const useProducerStore = defineStore('producer', {
	state: () => ({
		producer: null,
		searchResult: null
	}),

	actions: {
		async getProducer(id, identity) {
			try {
				const apiUrl = config[process.env.NODE_ENV].baseURL + `producers/${id}?identity=${identity}`;
				const res = await fetch(apiUrl);
				const data = await res.json();
				this.producer = data.data;

			} catch (error) {
				console.error('Error fetching producer:', error);
			}
		},
		async searchProducers(event, search, identity) {
			try {
				const apiUrl = config[process.env.NODE_ENV].baseURL + `events/${event}/search/producers/${search}?identity=${identity}`;
				const res = await fetch(apiUrl);
				const data = await res.json();
				this.searchResult = data.data;

			} catch (error) {
				console.error('Error fetching producer:', error);
			}
		},
	},
});
