<template>
	<ion-page>
		<ion-header :translucent="false">
			<ion-toolbar>
				<ion-buttons slot="start">
					<ion-back-button text="Zurück" :default-href="producer && producer.eventId !== undefined ? '/e/' + producer.eventId : '/'"></ion-back-button>
				</ion-buttons>
				<ion-title @click="goToRootPage()">WEIN & CO</ion-title>
				<ion-buttons slot="end">
					<ion-button @click="goToRootPage()">
						Meine Weine
					</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>

		<ion-content>
			<h3 class="ion-padding-horizontal ion-padding-top" size="large">{{ producer?.name }} <span style="font-weight: normal;" v-if="producer?.boothInfo">| {{ producer?.boothInfo }} <span v-if="producer?.booth2Info"> & {{ producer?.booth2Info }}</span></span></h3>
			<ion-list v-if="producer" class="ion-margin-top">
				<template v-for="(product) in producer?.products" :key="product">
					<ion-item detail="true" @click="goToProductPage(product)">
						<ion-thumbnail slot="start">
							<img class="ion-padding-vertical-sm object-contain" :src="product.imageUrl" :alt="product.name" />
						</ion-thumbnail>
						<ion-label>
							{{ product.name }}
						</ion-label>
					</ion-item>
				</template>
			</ion-list>
			<footer-content v-if="producer && !loading"></footer-content>
		</ion-content>
	</ion-page>
</template>

<script>
import { IonContent, IonButtons, IonButton, IonHeader, IonPage, IonTitle, IonToolbar, IonBackButton, IonList, IonItem, IonLabel, IonThumbnail } from '@ionic/vue';
import { defineComponent, onMounted, ref } from 'vue';
import { useIdentityStore } from '../stores/IdentityStore';
import { useProducerStore } from '../stores/ProducerStore';
import { useRouter } from 'vue-router';
import FooterContent from '../components/FooterContent.vue';

export default defineComponent({
	name: 'ProducerPage',
	props: ['id'],
	components: {
		IonContent,
		IonButtons,
		IonButton,
		IonHeader,
		IonPage,
		IonTitle,
		IonToolbar,
		IonBackButton,
		IonList,
		IonItem,
		IonLabel,
		IonThumbnail,
		FooterContent
	},
	setup(props) {
		const identityStore = useIdentityStore();
		const producerStore = useProducerStore();
		const loading = ref(false);
		const producer = ref([]);
		const router = useRouter();

		onMounted(async () => {
			loading.value = true;
			await producerStore.getProducer(props.id, identityStore.identity);
			loading.value = false;
			producer.value = producerStore.producer;
		});

		const goToRootPage = () => {
			router.push({
				name: 'Root',
				params: {},
			});
		};

		const goToProductPage = (product) => {
			router.push({
				name: 'Product',
				params: { id: product.token }
			})
		}

		return {
			loading,
			producer,
			goToRootPage,
			goToProductPage,
		};
	},
});
</script>
