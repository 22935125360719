<template>
	<ion-app :mode="appMode">
		<ion-router-outlet />
	</ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { useRouter } from 'vue-router';
import { defineComponent, onMounted, onBeforeMount, ref } from 'vue';
import { useIdentityStore } from './stores/IdentityStore';
import { validate as uuidValidate } from 'uuid';

export default defineComponent({
	name: 'App',
	components: {
		IonApp,
		IonRouterOutlet
	},
	setup() {
		const identityStore = useIdentityStore();
		const appMode = ref('');
		const router = useRouter();

		onBeforeMount(() => {
			if(router.currentRoute.value.query.i)
			{
				const incomingUuid = router.currentRoute.value.query.i;

				if (uuidValidate(incomingUuid)) {
					identityStore.identity = incomingUuid;
				}
			}

			if (!identityStore.identity) {
				identityStore.generateUuid();
			}
		}),

		onMounted(() => {
			// Set ios mode for desktop
			const isIOS = /(iPhone|iPod|iPad)/i.test(navigator.userAgent);
			const isAndroid = /Android/i.test(navigator.userAgent);

			if (isIOS) {
				appMode.value = 'ios';
			} else if (isAndroid) {
				appMode.value = 'md';
			} else {
				appMode.value = 'ios';
			}
		});

		return {
			appMode
		};
	}
});
</script>
