import { defineStore } from 'pinia';
import config from '../../config';

export const useBoothStore = defineStore('booth', {
	state: () => ({
		booth: null,
	}),

	actions: {
		async getBooth(eventId, boothId) {
			try {
				const apiUrl = config[process.env.NODE_ENV].baseURL + `events/${eventId}/booth/${boothId}`;
				const res = await fetch(apiUrl);
				const data = await res.json();
				this.booth = data.data;

			} catch (error) {
				console.error('Error fetching booth:', error);
			}
		},
	},
});
