<template>
	<ion-page>
		<ion-header :translucent="false">
			<ion-toolbar>
				<ion-buttons slot="start">
					<ion-back-button text="Zurück"></ion-back-button>
				</ion-buttons>
				<ion-title @click="goToRootPage()">WEIN & CO</ion-title>
				<ion-buttons slot="end">
					<ion-button @click="goToRootPage()">
						Meine Weine
					</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>

		<ion-content ref="fullContent">
			<div v-if="loading" class="ion-text-center">
				<br /><br /><br />
				<ion-spinner></ion-spinner>
			</div>
			<div v-if="event && !loading">
				<div v-if="event.id != 5">
					<img v-if="event.locationImageUrl" :src="event.locationImageUrl" :alt="event.title" />
					<h3 v-else class="ion-text-center ion-padding-horizontal ion-padding-top ion-margin-horizontal" size="large">{{ event.title }}</h3>
				</div>
				<div v-else>
					<map-content @changeSearchText="handleSearchTextChange"></map-content>
				</div>

				<ion-searchbar class="ion-margin-top" v-model="searchText" placeholder="Suche Produzent, Land, Region"></ion-searchbar>

				<template v-if="noProducersFound">
					<div class="ion-text-center ion-padding">
						Keine Produzenten gefunden.
					</div>
				</template>

				<template v-else>
					<template v-for="(booth) in nonEmptyBooths" :key="booth">
						<h3 class="ion-padding-horizontal" style="margin-bottom:0px;" size="large">{{ booth.name }}</h3>
						<div v-if="booth.description" class="ion-padding-horizontal ion-padding-top">{{ booth.description }}</div>
						<ion-list style="margin-bottom: 32px;">
							<template v-for="(producer) in booth.producers" :key="producer">
								<ion-item detail="true" @click="goToProducerPage(producer)">
									<ion-label>
										{{ producer?.name }}
									</ion-label>
								</ion-item>
							</template>
						</ion-list>
					</template>
				</template>

				<div v-if="activeBoothSearch" class="ion-text-center ion-margin-bottom">
					<ion-button @click="resetSearch()">Alle Stände anzeigen</ion-button>
				</div>

				<div v-if="event.footerImageUrl" style="margin-top:40px;margin-bottom:-30px;">
					<ion-img class="ion-margin-top" :src="event.footerImageUrl" :alt="event.name"></ion-img>
				</div>
			</div>
			<footer-content v-if="event && !loading"></footer-content>
		</ion-content>
	</ion-page>
</template>

<script>
import { IonSearchbar, IonImg, IonContent, IonButton, IonButtons, IonHeader, IonSpinner, IonPage, IonTitle, IonToolbar, IonBackButton, IonList, IonItem, IonLabel } from '@ionic/vue';
import { defineComponent, onMounted, ref, computed, watchEffect } from 'vue';
import { useIdentityStore } from '../stores/IdentityStore';
import { useEventBoothStore } from '../stores/EventBoothStore';
import { useRouter } from 'vue-router';
import FooterContent from '../components/FooterContent.vue';
import MapContent from '../components/MapContent.vue';

export default defineComponent({
	name: 'EventBoothsPage',
	props: ['id'],
	components: {
		IonSearchbar,
		IonButtons,
		IonContent,
		IonHeader,
		IonButton,
		IonPage,
		IonTitle,
		IonBackButton,
		IonToolbar,
		IonList,
		IonItem,
		IonImg,
		IonLabel,
		IonSpinner,
		FooterContent,
		MapContent
	},
	setup(props) {
		const eventBoothStore = useEventBoothStore();
		const identityStore = useIdentityStore();
		const loading = ref(false);
		const event = ref(null);
		const router = useRouter();
		const searchText = ref('');
		const fullContent = ref('');

		watchEffect(() => {
			const queryBooth = router.currentRoute.value.query.f;
			if (queryBooth) {
				searchText.value = queryBooth;
			}
		});

		onMounted(async () => {
			loading.value = true;
			await eventBoothStore.getEventBooths(props.id, identityStore.identity);
			event.value = eventBoothStore.event;
			loading.value = false;
		});

		const nonEmptyBooths = computed(() => {
			let searchKeywords = searchText.value.toLowerCase().trim().split(' ');
			const boothTokensToFilter = searchKeywords
				.filter(keyword => keyword.startsWith('stand-'))
				.map(keyword => keyword.substring(6).toLowerCase());

			searchKeywords = searchKeywords.filter(keyword => !keyword.startsWith('stand-'));

			return event.value.booths
				.filter(booth => booth.producers.length > 0)
				.filter(booth => {
					if (boothTokensToFilter.length === 0) {
						return true; // Wenn keine Stand-Suchbegriffe vorhanden sind, beibehalte alle Booths
					} else {
						return boothTokensToFilter.includes(booth.token); // Behalte nur die Booths, deren token den Stand-Buchstaben oder -String enthalten
					}
				})
				.map(booth => {
					const matchingProducers = booth.producers.filter(producer =>
						searchKeywords.every(keyword => {
							keyword = keyword.toLowerCase();
							if (keyword.length <= 3) {
								return (
									(producer.name && producer.name.toLowerCase().includes(keyword)) ||
									(producer.country && producer.country.toLowerCase().includes(keyword)) ||
									(producer.region && producer.region.toLowerCase().includes(keyword)) ||
									(producer.boothInfo && producer.boothInfo.toLowerCase() == keyword) ||
									(producer.booth2Info && producer.booth2Info.toLowerCase() === keyword)
								);
							} else {
								return (
									(producer.name && producer.name.toLowerCase().includes(keyword)) ||
									(producer.country && producer.country.toLowerCase().includes(keyword)) ||
									(producer.region && producer.region.toLowerCase().includes(keyword)) ||
									(producer.boothInfo && producer.boothInfo.toLowerCase().includes(keyword)) ||
									(producer.booth2Info && producer.booth2Info.toLowerCase().includes(keyword))
								);
							}
						})
					);

					if (matchingProducers.length > 0) {
						return {
							...booth,
							producers: matchingProducers
						};
					}

					return null;
				})
				.filter(booth => booth !== null);
		});

		const noProducersFound = computed(() => {
			return nonEmptyBooths.value.every(booth => booth.producers.length === 0);
		});

		const activeBoothSearch = computed(() => {
			return searchText.value.toLowerCase().includes('stand-');
		});

		const goToRootPage = () => {
			router.push({
				name: 'Root',
				params: {},
			});
		};

		const resetSearch = () => {
			scrollTop();
			searchText.value = '';
		}

		const scrollTop = () => {
			fullContent.value.$el.scrollToPoint(0, 0, 0);
		}

		const handleSearchTextChange = (value) => {
			searchText.value = value;
		};

		const goToProducerPage = (producer) => {
			router.push({
				name: 'Producer',
				params: { id: producer.token },
			});
		}

		const goToBoothPage = (booth) => {
			router.push({
				name: 'Booth',
				params: {
					id: event.value.token,
					boothId: booth.token
				},
			});
		}

		return {
			loading,
			fullContent,
			event,
			searchText,
			activeBoothSearch,
			resetSearch,
			handleSearchTextChange,
			nonEmptyBooths,
			noProducersFound,
			goToRootPage,
			goToProducerPage,
			goToBoothPage,
		};
	},
});
</script>

<style scoped>
ion-spinner {
	--color: #bc0936;
}
</style>
