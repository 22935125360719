import { defineStore } from 'pinia';
import config from '../../config';

export const useEventStore = defineStore('event', {
	state: () => ({
		events: null,
		event: null
	}),

	actions: {
		async getEvents(identity) {
			try {
				const apiUrl = config[process.env.NODE_ENV].baseURL + `events?identity=${identity}`;
				const res = await fetch(apiUrl);
				const data = await res.json();
				this.events = data.data;

			} catch (error) {
				console.error('Error fetching event:', error);
			}
		},
		async getEvent(id, identity) {
			try {
				const apiUrl = config[process.env.NODE_ENV].baseURL + `events/${id}?identity=${identity}`;
				const res = await fetch(apiUrl);
				const data = await res.json();
				this.event = data.data;

			} catch (error) {
				console.error('Error fetching event:', error);
			}
		},
		async sendListPerEmail(event, email, identity) {
			const body = {
				"email": email,
			}

			const apiUrl = config[process.env.NODE_ENV].baseURL + `events/${event}/mail?identity=${identity}`;
			await fetch(apiUrl, {
				method: 'POST',
				body: JSON.stringify(body),
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
				}
			})
		}
	},
});
