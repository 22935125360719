import { createRouter, createWebHistory } from '@ionic/vue-router';
import RootPage from '../views/RootPage.vue';
import ProductPage from '../views/ProductPage.vue';
import ProducerPage from '../views/ProducerPage.vue';
import EventPage from '../views/EventPage.vue';
import EventSearchPage from '../views/EventSearchPage.vue';
import EventBoothsPage from '../views/EventBoothsPage.vue';
import BoothPage from '../views/BoothPage.vue';
import ImprintPage from '../views/ImprintPage.vue';

const routes = [
	{
		path: '/',
		redirect: '/root'
	},
	{
		path: '/root',
		name: 'Root',
		component: RootPage
	},
	{
		path: '/imprint',
		name: 'Imprint',
		component: ImprintPage
	},
	{
		path: '/p/:id',
		name: 'Product',
		props: true,
		component: ProductPage
	},
	{
		path: '/c/:id',
		name: 'Producer',
		props: true,
		component: ProducerPage
	},
	{
		path: '/e/:id',
		name: 'Event',
		props: true,
		component: EventPage
	},
	{
		path: '/s/:id',
		name: 'EventSearch',
		props: true,
		component: EventSearchPage
	},
	{
		path: '/e/:id/booths',
		name: 'EventBooths',
		props: true,
		component: EventBoothsPage
	},
	{
		path: '/e/:id/b/:boothId',
		name: 'Booth',
		props: true,
		component: BoothPage
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router
