const config = {
	development: {
		baseURL: 'https://wine.test/api/',
	},
	production: {
		baseURL: 'https://api.mein.weinco.at/api/',
	},
};

export default config;
