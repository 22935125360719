<template>
	<ion-page>
		<ion-header :translucent="false">
			<ion-toolbar>
				<ion-buttons slot="start">
					<ion-back-button text="Zurück"></ion-back-button>
				</ion-buttons>
				<ion-title @click="goToRootPage()">WEIN & CO</ion-title>
				<ion-buttons slot="end">
					<ion-button @click="goToRootPage()">
						Meine Weine
					</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>

		<ion-content>
			<div v-if="loading" class="ion-text-center">
				<br /><br /><br />
				<ion-spinner></ion-spinner>
			</div>
			<div v-if="eventStore?.event && !loading">
				<ion-img v-if="eventStore?.event.imageUrl" :src="eventStore?.event.imageUrl" :alt="eventStore?.event.title" @click="gotoSwitch(eventStore?.event)"></ion-img>
				<h3 v-else class="ion-text-center ion-padding-horizontal ion-padding-top ion-margin-horizontal" size="large">{{ eventStore?.event.title }}</h3>
				<div class="ion-padding-horizontal ion-padding-top">
					<ion-segment v-model="selectedMode">
						<ion-segment-button value="producers">
							<ion-label>Produzenten</ion-label>
						</ion-segment-button>
						<ion-segment-button value="products">
							<ion-label>Produkte</ion-label>
						</ion-segment-button>
					</ion-segment>
				</div>
				<ion-searchbar class="ion-margin-top" v-model="searchText" :placeholder="selectedMode === 'producers' ? 'Suche nach Produzent, Land, Region' : 'Suche nach Produkt'"></ion-searchbar>
				<ion-list class="ion-margin-top" v-if="selectedMode === 'producers'">
					<template v-if="filteredProducers.length > 0">
						<template v-for="(producer) in filteredProducers" :key="producer">
							<ion-item detail="true" @click="goToProducerPage(producer)">
								<ion-label>
									{{ producer?.name }} <span v-if="producer?.boothInfo"> | <strong>{{ producer?.boothInfo }}</strong></span>
								</ion-label>
							</ion-item>
						</template>
					</template>
					<template v-else>
						<div class="ion-text-center ion-padding">
							Keine Produzenten gefunden.
						</div>
					</template>
				</ion-list>
				<ion-list class="ion-margin-top" v-if="selectedMode === 'products'">
					<template v-if="filteredProducts.length > 0">
						<template v-for="(product) in filteredProducts" :key="product">
							<ion-item detail="true" @click="goToProductPage(product)">
								<ion-label>
									{{ product?.name }} <span v-if="product?.producerBoothInfo"> | <strong>{{ product?.producerBoothInfo }}</strong></span>
								</ion-label>
							</ion-item>
						</template>
					</template>
					<template v-else>
						<div class="ion-text-center ion-padding">
							Keine Produkte gefunden.
						</div>
					</template>
				</ion-list>

				<div v-if="eventStore?.event.description && eventStore?.event.showDescriptionOnRootPage" class="" style="margin-top:0px; margin-bottom:0px;">
					<div class="ion-padding-horizontal ion-padding-top" v-html="eventStore?.event.description"></div>
				</div>

				<div v-if="eventStore?.event.footerImageUrl" style="margin-top:40px;margin-bottom:-30px;">
					<ion-img class="ion-margin-top" :src="eventStore?.event.footerImageUrl" :alt="eventStore?.event.name"></ion-img>
				</div>
			</div>
			<footer-content v-if="eventStore?.event && !loading"></footer-content>
		</ion-content>
	</ion-page>
</template>

<script>
import { IonSearchbar, IonContent, IonHeader, IonSpinner, IonPage, IonTitle, IonToolbar, IonBackButton, IonList, IonItem, IonLabel, IonSegment, IonSegmentButton } from '@ionic/vue';
import { defineComponent, onMounted, ref, watch, watchEffect, computed } from 'vue';
import { useIdentityStore } from '../stores/IdentityStore';
import { useEventStore } from '../stores/EventStore';
import { useRouter } from 'vue-router';
import FooterContent from '../components/FooterContent.vue';

export default defineComponent({
	name: 'EventPage',
	props: ['id'],
	components: {
		IonSearchbar,
		IonContent,
		IonHeader,
		IonPage,
		IonTitle,
		IonBackButton,
		IonToolbar,
		IonList,
		IonItem,
		IonLabel,
		IonSpinner,
		IonSegment,
		IonSegmentButton,
		FooterContent
	},
	setup(props) {
		const searchText = ref('');
		const eventStore = useEventStore();
		const identityStore = useIdentityStore();
		const loading = ref(false);
		const selectedMode = ref('producers');
		const router = useRouter();

		watchEffect(() => {
			const queryMode = router.currentRoute.value.query.mode;
			if (queryMode) {
				selectedMode.value = queryMode;
			}
		});

		watch(selectedMode, (newMode) => {
			router.push({ query: { mode: newMode } });
		});

		onMounted(async () => {
			loading.value = true;
			await eventStore.getEvent(props.id, identityStore.identity);
			loading.value = false;
		});

		const filteredProducers = computed(() => {
			const searchTerms = searchText.value.toLowerCase().split(' '); // Split search terms
			if (eventStore.event && eventStore.event.producers) {
				return eventStore.event.producers.filter((producer) => {
					return searchTerms.every((keyword) =>
						(producer.name && producer.name.toLowerCase().includes(keyword)) ||
						(producer.country && producer.country.toLowerCase().includes(keyword)) ||
						(producer.region && producer.region.toLowerCase().includes(keyword)) ||
						(producer.boothInfo && producer.boothInfo.toLowerCase().includes(keyword))
					);
				});
			}
			return [];
		});

		const filteredProducts = computed(() => {
			const searchTerms = searchText.value.toLowerCase().split(' ');
			if (eventStore.event && eventStore.event.products) {
				return eventStore.event.products.filter((product) => {
					return searchTerms.every((keyword) =>
						(product.name.toLowerCase().includes(keyword)) ||
						(product.producerBoothInfo && product.producerBoothInfo.toLowerCase().includes(keyword))
					);
				});
			}
			return [];
		});


		watch(selectedMode, () => {
			searchText.value = '';
		});

		const goToRootPage = () => {
			router.push({
				name: 'Root',
				params: {},
			});
		};

		const goToProducerPage = (producer) => {
			router.push({
				name: 'Producer',
				params: { id: producer.token },
			});
		}

		const goToProductPage = (product) => {
			router.push({
				name: 'Product',
				params: { id: product.token }
			})
		}

		const gotoSwitch = (event) => {
			if (event.numberOfBooths > 0) {
				router.push({
					name: 'EventBooths',
					params: { id: event.token }
				})
			} else {
				router.push({
					name: 'Root',
					params: {},
				});
			}
		}

		return {
			loading,
			eventStore,
			selectedMode,
			gotoSwitch,
			goToRootPage,
			goToProducerPage,
			goToProductPage,
			searchText,
			filteredProducers,
			filteredProducts,
		};
	},
});
</script>

<style scoped>
ion-spinner {
	--color: #bc0936;
}
</style>
