import App from './App.vue';
import router from './router';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';
import './theme/variables.css';
import './theme/wine.css';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
//import './registerServiceWorker';

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = createApp(App)
	.use(IonicVue, {
		rippleEffect: false,
		swipeBackEnabled: false,
	})
	.use(router)
	.use(pinia);

router.isReady().then(() => {
	app.mount('#app');
});
