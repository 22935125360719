import { defineStore } from 'pinia';
import config from '../../config';

export const useEventBoothStore = defineStore('eventBooth', {
	state: () => ({
		event: null
	}),

	actions: {
		async getEventBooths(id) {
			try {
				const apiUrl = config[process.env.NODE_ENV].baseURL + `events/${id}/booths`;
				const res = await fetch(apiUrl);
				const data = await res.json();
				this.event = data.data;

			} catch (error) {
				console.error('Error fetching event:', error);
			}
		},
	},
});
