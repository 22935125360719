import { defineStore } from 'pinia';
import config from '../../config';

export const useProductStore = defineStore('product', {
	state: () => ({
		product: null,
		searchResult: null,
	}),

	actions: {
		async getProduct(id, identity) {
			try {
				const apiUrl = config[process.env.NODE_ENV].baseURL + `products/${id}?identity=${identity}`;
				const res = await fetch(apiUrl);
				const data = await res.json();
				this.product = data.data;

			} catch (error) {
				console.error('Error fetching producer:', error);
			}
		},
		async searchProducts(event, search, identity) {
			try {
				const apiUrl = config[process.env.NODE_ENV].baseURL + `events/${event}/search/products/${search}?identity=${identity}`;
				const res = await fetch(apiUrl);
				const data = await res.json();
				this.searchResult = data.data;

			} catch (error) {
				console.error('Error fetching producer:', error);
			}
		},
		async addRating(id, identity, product, rating, note) {
			const body = {
				"product": product,
				"rating": rating,
				"note": note
			}

			const apiUrl = config[process.env.NODE_ENV].baseURL + `products/${id}/ratings?identity=${identity}`;
			await fetch(apiUrl, {
				method: 'POST',
				body: JSON.stringify(body),
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
				}
			})
		},
		async deleteRating(product, identity) {
			const body = {
				"product": product.id
			}

			const apiUrl = config[process.env.NODE_ENV].baseURL + `products/${product.token}/ratings/delete?identity=${identity}`;
			await fetch(apiUrl, {
				method: 'POST',
				body: JSON.stringify(body),
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
				}
			})
		}
	},
});
