<template>
	<ion-page>
		<ion-header :translucent="false">
			<ion-toolbar>
				<ion-buttons slot="start">
					<ion-back-button text="Zurück" :default-href="product && product.producerToken !== undefined ? '/c/' + product.producerToken : '/'">Zurück</ion-back-button>
				</ion-buttons>
				<ion-title @click="goToRootPage()">WEIN & CO</ion-title>
				<ion-buttons slot="end">
					<ion-button @click="() => this.$router.push({ name: 'Root' })">
						Meine Weine
					</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>

		<ion-content class="ion-text-center">
			<div v-if="loading">
				<br /><br /><br />
				<ion-spinner></ion-spinner>
			</div>
			<div v-if="product && !loading">
				<div>
					<h3 class="ion-text-center ion-padding-horizontal ion-padding-top ion-margin-horizontal" style="margin-bottom:0px" size="large">{{ product.name }}</h3>
					<div @click="goToProducerPage()" class="ion-margin-bottom" v-if="product.producerName">{{ product.producerName }} <span v-if="product?.producerBoothInfo"> | <strong>{{ product?.producerBoothInfo }}</strong><span v-if="product?.producerBooth2Info"> & <strong>{{ product?.producerBoothInfo }}</strong></span></span></div>

					<ion-text class="ion-text-center" color="primary" v-if="!product?.inStock">
						<strong>derzeit nicht verfügbar</strong>
					</ion-text>
					<div v-if="product.inStock && product.price" v-html="product.price"></div>

					<ion-img class="ion-padding-top" :src="product.imageUrl" :alt="product.name"></ion-img>
					<br v-if="rating" />
					<star-rating v-if="rating" :read-only="true" class="ion-justify-content-center" :star-size="20" :show-rating="false" v-model:rating="rating"></star-rating>
					<ion-text v-if="note" class="ion-margin-top ion-padding-horizontal" style="color:#6b7280" v-html="note"></ion-text>
				</div>
				<div class="ion-padding ion-margin-top">
					<ion-button expand="block" shape="round" @click="openModal">
						<span v-if="!rating">Jetzt bewerten</span>
						<span v-else>Meine Bewertung ändern</span>
					</ion-button>
					<ion-button v-if="rating" class="ion-margin-top" expand="block" fill="clear" shape="round" @click="deleteRating()">
						<ion-icon slot="start" :icon="trash"></ion-icon>
						Bewertung löschen
					</ion-button>
					<ion-button v-if="!rating && !bookmarked" class="ion-margin-top" expand="block" fill="clear" shape="round" @click="bookmarkProduct()">
						<template v-if="!toggledBookmark">
							<ion-icon slot="start" :icon="bookmarkOutline"></ion-icon>
							Merken
						</template>
						<template v-if="toggledBookmark">
							<ion-spinner v-if="toggledBookmark" color="primary" name="dots"></ion-spinner>
						</template>
					</ion-button>
					<ion-button v-if="!rating && bookmarked" class="ion-margin-top" expand="block" fill="clear" shape="round" @click="deleteRating()">
						<template v-if="!toggledBookmark">
							<ion-icon slot="start" :icon="bookmark"></ion-icon>
							Gemerkt
						</template>
						<template v-if="toggledBookmark">
							<ion-spinner v-if="toggledBookmark" color="primary" name="dots"></ion-spinner>
						</template>
					</ion-button>
				</div>
				<div v-if="product.description" class="ion-margin-top">
					<ion-text v-if="product.description" class="ion-text-left ion-padding-horizontal" style="color:#6b7280;display:block;" v-html="product.description"></ion-text>
				</div>
				<div v-if="product.inStock" style="padding-left:12%;padding-right:12%;margin-top:35px;color:#6b7280">
					Preis inkl. MwSt. und zzgl. Versandkosten. <span v-if="product.orderShopUrl">Jetzt direkt im
						<a target="_blank" :href="product.orderShopUrl" style="text-decoration: none;">Online Shop</a> bestellen.</span>
				</div>
			</div>
			<footer-content v-if="product && !loading"></footer-content>
		</ion-content>
	</ion-page>
</template>

<script>
import { IonIcon, IonContent, IonSpinner, IonText, IonHeader, IonPage, IonTitle, IonToolbar, IonButton, IonButtons, IonBackButton, IonImg, modalController } from '@ionic/vue';
import { defineComponent, onMounted, ref } from 'vue';
import { useIdentityStore } from '../stores/IdentityStore';
import { useProductStore } from '../stores/ProductStore';
import { useRouter } from 'vue-router';
import RatingModal from '../components/RatingModal.vue';
import FooterContent from '../components/FooterContent.vue';
import StarRating from 'vue-star-rating';
import { trash, bookmark, bookmarkOutline } from 'ionicons/icons';

export default defineComponent({
	name: 'ProductPage',
	props: ['id'],
	components: {
		IonSpinner,
		IonIcon,
		IonContent,
		IonHeader,
		IonPage,
		IonTitle,
		IonToolbar,
		IonButton,
		IonButtons,
		IonBackButton,
		IonImg,
		IonText,
		StarRating,
		FooterContent
	},
	setup(props) {
		const identityStore = useIdentityStore();
		const productStore = useProductStore();
		const loading = ref(false);
		const toggledBookmark = ref(false);
		const rating = ref(null);
		const note = ref(null);
		const bookmarked = ref(false);
		const product = ref(null);
		const router = useRouter();

		onMounted(async () => {
			loading.value = true;
			await productStore.getProduct(props.id, identityStore.identity);
			product.value = productStore.product;

			if (productStore?.product?.ratings?.length > 0) {
				rating.value = productStore?.product?.ratings[0].rating;
				note.value = productStore?.product?.ratings[0].note;

				if (!rating.value && !note.value)
				{
					bookmarked.value = true;
				}
			}

			loading.value = false;
		});

		const goToRootPage = () => {
			router.push({
				name: 'Root',
				params: {},
			});
		};

		async function goToProducerPage() {
			if (product.value && product.value.producerToken !== undefined) {
				router.push({
					name: 'Producer',
					params: { id: product.value.producerToken },
				});
			}
		}

		const deleteRating = async () => {
			toggledBookmark.value = true;
			await productStore.deleteRating(product.value, identityStore.identity);
			rating.value = null;
			note.value = null;
			bookmarked.value = false;
			toggledBookmark.value = false;
		};

		const bookmarkProduct = async () => {
			toggledBookmark.value = true;
			await productStore.addRating(props.id, identityStore.identity, productStore.product.id, null, null);
			bookmarked.value = true;
			rating.value = null;
			note.value = null;
			toggledBookmark.value = false;
		};

		async function openModal() {
			const modal = await modalController.create({
				component: RatingModal,
				componentProps: {
					existingRating: rating.value,
					existingNote: note.value,
				},
			});
			modal.present();

			const { data, role } = await modal.onWillDismiss();

			if (role === 'confirm') {
				if (rating.value != data.rating || note.value != data.note) {
					rating.value = data.rating;
					note.value = data.note;
					await productStore.addRating(props.id, identityStore.identity, productStore.product.id, data.rating, data.note);
				}
			}
		}

		return {
			goToRootPage,
			goToProducerPage,
			openModal,
			bookmarkProduct,
			deleteRating,
			rating,
			note,
			bookmarked,
			loading,
			toggledBookmark,
			product,
			trash,
			bookmark,
			bookmarkOutline
		};
	},
});
</script>

<style scoped>
ion-img,
.img-skeleton {
	width: 140px !important;
	height: 200px !important;
	margin-left: auto;
	margin-right: auto;
}

ion-spinner {
	--color: #bc0936;
}
</style>
