<template>
	<div class="ion-text-center" style="color:rgb(111, 101, 100);padding-top:50px;padding-bottom:30px;">
		<span style="cursor:pointer;color:rgb(111, 101, 100);text-decoration: none;" @click="goToImprintPage()">Impressum</span> | <a style="color:rgb(111, 101, 100);text-decoration: none;" href="https://www.weinco.at/footer/information/datenschutz" target="_blank">Datenschutz</a>
	</div>
</template>

<script>

import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
	name: 'FooterContent',
	setup() {
		const router = useRouter();

		const goToImprintPage = () => {
			router.push({
				name: 'Imprint'
			})
		};

		return {
			goToImprintPage
		}
	},
});
</script>
