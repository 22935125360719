<template>
	<ion-page>
		<ion-header :translucent="false">
			<ion-toolbar>
				<ion-buttons slot="start">
					<ion-back-button text="Zurück"></ion-back-button>
				</ion-buttons>
				<ion-title @click="goToRootPage()">WEIN & CO</ion-title>
				<ion-buttons slot="end">
					<ion-button @click="goToRootPage()">
						Meine Weine
					</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>

		<ion-content>
			<div v-if="loading" class="ion-text-center">
				<br /><br /><br />
				<ion-spinner></ion-spinner>
			</div>
			<div v-if="event && !loading">
				<ion-img v-if="event.imageUrl" :src="event.imageUrl" :alt="event.title" @click="gotoSwitch(event)"></ion-img>
				<h3 v-else class="ion-text-center ion-padding-horizontal ion-padding-top ion-margin-horizontal" size="large">{{ event.title }}</h3>
				<div class="ion-padding-horizontal ion-padding-top">
					<ion-segment v-model="selectedMode">
						<ion-segment-button value="producers">
							<ion-label>Produzenten</ion-label>
						</ion-segment-button>
						<ion-segment-button value="products">
							<ion-label>Produkte</ion-label>
						</ion-segment-button>
					</ion-segment>
				</div>
				<ion-searchbar v-if="selectedMode === 'producers'" :debounce="650" v-model="searchTextProducers" @ionInput="onProducersSearchChange()" class="ion-margin-top" placeholder="Produzent, Land, Region, Stand"></ion-searchbar>
				<ion-searchbar v-if="selectedMode === 'products'" :debounce="650" v-model="searchTextProducts" @ionInput="onProductsSearchChange()" class="ion-margin-top" placeholder="Produkt, Land, Region, Stand"></ion-searchbar>
				<ion-list v-if="selectedMode === 'products' && productStore?.searchResult?.length > 1">
					<ion-item>
						<ion-select v-model="sortCriteria" label="Sortierung" placeholder="Sortierung">
							<ion-select-option value="name">Name</ion-select-option>
							<ion-select-option value="booth">Stand</ion-select-option>
							<ion-select-option value="producer">Produzent</ion-select-option>
						</ion-select>
					</ion-item>
				</ion-list>


				<ion-list class="ion-margin-top" v-if="selectedMode === 'producers'">
					<template v-if="producerStore?.searchResult?.length > 0">
						<template v-for="(producer) in producerStore?.searchResult" :key="producer">
							<ion-item detail="true" @click="goToProducerPage(producer)">
								<ion-label>
									{{ producer?.name }} <span v-if="producer?.boothInfo"> | <strong>{{ producer?.boothInfo }}</strong><span v-if="producer?.booth2Info"> & <strong>{{ producer?.booth2Info }}</strong></span></span>
								</ion-label>
							</ion-item>
						</template>
					</template>
					<template v-else>
						<div class="ion-text-center ion-padding" v-if="searching">
							<ion-spinner></ion-spinner>
						</div>
						<div v-else class="ion-text-center ion-padding">
							Bitte geben Sie einen Suchbegriff ein.
						</div>
					</template>
				</ion-list>
				<ion-list class="ion-margin-top" v-if="selectedMode === 'products'">
					<template v-if="productStore?.searchResult?.length > 0">
						<template v-for="(product) in productStore?.searchResult" :key="product">
							<ion-item detail="true" @click="goToProductPage(product)">
								<ion-label>
									{{ product?.name }}<br>
									<small><span v-if="product?.producerBoothInfo"><strong>{{ product?.producerBoothInfo }}</strong><span v-if="product?.producerBooth2Info"> & <strong>{{ product?.producerBooth2Info }}</strong></span> | </span><i>{{ product?.producerName }}</i></small>
								</ion-label>
							</ion-item>
						</template>
					</template>
					<template v-else>
						<div class="ion-text-center ion-padding" v-if="searching">
							<ion-spinner></ion-spinner>
						</div>
						<div v-else class="ion-text-center ion-padding">
							Bitte geben Sie einen Suchbegriff ein.
						</div>
					</template>
				</ion-list>
				<div v-if="event.footerImageUrl" style="margin-top:40px;margin-bottom:-30px;">
					<ion-img class="ion-margin-top" :src="event.footerImageUrl" :alt="event.name"></ion-img>
				</div>
			</div>
			<footer-content v-if="event && !loading"></footer-content>
		</ion-content>
	</ion-page>
</template>

<script>
import { IonSearchbar, IonImg, IonContent, IonButtons, IonButton, IonHeader, IonSelect, IonSelectOption, IonSpinner, IonPage, IonTitle, IonToolbar, IonBackButton, IonList, IonItem, IonLabel, IonSegment, IonSegmentButton } from '@ionic/vue';
import { defineComponent, onMounted, ref, watch, watchEffect } from 'vue';
import { useIdentityStore } from '../stores/IdentityStore';
import { useEventStore } from '../stores/EventStore';
import { useProducerStore } from '../stores/ProducerStore';
import { useProductStore } from '../stores/ProductStore';
import { useRouter } from 'vue-router';
import FooterContent from '../components/FooterContent.vue';

export default defineComponent({
	name: 'EventSearchPage',
	props: ['id'],
	components: {
		IonSearchbar,
		IonImg,
		IonButtons,
		IonButton,
		IonContent,
		IonHeader,
		IonPage,
		IonTitle,
		IonBackButton,
		IonToolbar,
		IonList,
		IonSelect,
		IonSelectOption,
		IonItem,
		IonLabel,
		IonSpinner,
		IonSegment,
		IonSegmentButton,
		FooterContent
	},
	setup(props) {
		const searchTextProducers = ref('');
		const searchTextProducts = ref('');
		const eventStore = useEventStore();
		const productStore = useProductStore();
		const producerStore = useProducerStore();
		const identityStore = useIdentityStore();
		const loading = ref(false);
		const event = ref(null);
		const selectedMode = ref('producers');
		const sortCriteria = ref('name');
		const router = useRouter();
		const searching = ref(false);

		const sortResults = (sortBy) => {
			const sortFunctions = {
				name: (a, b) => a.name.localeCompare(b.name),
				booth: (a, b) => {
					if (a.producerBoothInfo && b.producerBoothInfo) {
						return a.producerBoothInfo.localeCompare(b.producerBoothInfo);
					} else if (!a.producerBoothInfo) {
						return 1; // Place products with null producerBoothInfo at the end
					} else if (!b.producerBoothInfo) {
						return -1; // Place products with null producerBoothInfo at the end
					} else {
						return 0; // Both producerBoothInfo values are null
					}
				},
				producer: (a, b) => a.producerName.localeCompare(b.producerName),
			};

			productStore.searchResult.sort(sortFunctions[sortBy]);
		};

		watch(sortCriteria, (newSortCriteria) => {
			sortResults(newSortCriteria);
		});

		watchEffect(() => {
			const queryMode = router.currentRoute.value.query.mode;
			if (queryMode) {
				selectedMode.value = queryMode;
			}
		});

		watch(searchTextProducers, () => {
			if (searchTextProducers?.value?.length < 2) {
				producerStore.searchResult = null;
			}
		});

		watch(searchTextProducts, () => {
			if (searchTextProducts?.value?.length < 2) {
				productStore.searchResult = null;
			}
		});

		watch(selectedMode, (newMode) => {
			router.push({ query: { mode: newMode } });
		});

		onMounted(async () => {
			loading.value = true;
			await eventStore.getEvent(props.id, identityStore.identity);
			event.value = eventStore.event;
			loading.value = false;
		});

		const onProducersSearchChange = async () => {
			if (searchTextProducers.value.length >= 2) {
				searching.value = true;
				await producerStore.searchProducers(event.value.token, searchTextProducers.value.toLowerCase(), identityStore.identity);
				searching.value = false;
			}
		}

		const onProductsSearchChange = async () => {
			if (searchTextProducts.value.length >= 2) {
				searching.value = true;
				await productStore.searchProducts(event.value.token, searchTextProducts.value.toLowerCase(), identityStore.identity);
				searching.value = false;
			}
		}

		const goToRootPage = () => {
			router.push({
				name: 'Root',
				params: {},
			});
		};

		const goToProducerPage = (producer) => {
			router.push({
				name: 'Producer',
				params: { id: producer.token },
			});
		}

		const goToProductPage = (product) => {
			router.push({
				name: 'Product',
				params: { id: product.token }
			})
		}

		const gotoSwitch = (event) => {
			if (event.numberOfBooths > 0) {
				router.push({
					name: 'EventBooths',
					params: { id: event.token }
				})
			} else {
				router.push({
					name: 'Root',
					params: {},
				});
			}
		}

		return {
			loading,
			event,
			selectedMode,
			sortCriteria,
			sortResults,
			gotoSwitch,
			goToRootPage,
			goToProducerPage,
			goToProductPage,
			searchTextProducers,
			searchTextProducts,
			onProducersSearchChange,
			onProductsSearchChange,
			producerStore,
			productStore,
			searching
		};
	},
});
</script>

<style scoped>
ion-spinner {
	--color: #bc0936;
}
</style>
