import { defineStore } from 'pinia';
import { generateUniqueId } from '../uuid';

export const useIdentityStore = defineStore('identity', {
	state: () => ({
		identity: null,
	}),

	actions: {
		generateUuid() {
			this.identity = generateUniqueId();
		}
	},

	persist: true,
});
